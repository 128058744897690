import "requestidlecallback-polyfill";

import { Application } from "@hotwired/stimulus";

import SwupController from "./controllers/swup-controller";
import StimulusControllerResolver, {
  createViteGlobResolver,
  identifierForGlobKey,
} from "./resolver";

const staticControllers = {
  swup: SwupController,
} as const;

const staticControllerKeys = Object.keys(staticControllers);

const application = Application.start();

console.debug(
  `%c⏚ Loading static controllers: ${staticControllerKeys.join(", ")}`,
  "color: #aaaa00",
);

Object.entries(staticControllers).forEach(([key, controllerModule]) => {
  application.register(key, controllerModule);
});

StimulusControllerResolver.install(
  application,
  createViteGlobResolver(
    Object.fromEntries(
      Object.entries(
        import.meta.glob("./controllers/**/*-controller.{js,ts}"),
      ).filter(([key]) => {
        const identifier = identifierForGlobKey(key);

        return identifier && !staticControllerKeys.includes(identifier);
      }),
    ),
  ),
);
