import gsap from 'gsap';
import ApplicationController from '.';

type AnimationState = 'intro' | 'outro';

export default class extends ApplicationController<HTMLElement> {
  static targets = ['canvas'];

  declare readonly canvasTarget?: HTMLCanvasElement;
  declare readonly hasCanvasTarget: boolean;

  static values = {
    from: Number,
    midpoint: Number,
    to: Number,
    padLength: { type: Number, default: 4 },
    path: String,
    mobilePath: String,
    extension: { type: String, default: 'png' },
    duration: { type: Number, default: 2 },
  };

  declare fromValue?: number;
  declare readonly hasFromValue: boolean;

  declare midpointValue?: number;
  declare readonly hasMidpointValue: boolean;

  declare toValue?: number;
  declare readonly hasToValue: boolean;

  declare padLengthValue: number;
  declare readonly hasPadLengthValue: boolean;

  declare pathValue?: string;
  declare readonly hasPathValue: boolean;

  declare mobilePathValue?: string;
  declare readonly hasMobilePathValue: boolean;

  declare extensionValue: string;
  declare readonly hasExtensionValue: boolean;

  declare durationValue: number;
  declare readonly hasDurationValue: boolean;

  private preloadPromise?: Promise<PromiseSettledResult<void>[]>;

  private images: Map<number, HTMLImageElement> = new Map();
  private ctx: CanvasRenderingContext2D | null = null;
  private internalCurrentIndex: number = 0;
  private currentTween?: gsap.core.Tween;
  private currentAnimation: AnimationState | null = null;

  connect(): void {
    super.connect();

    this.whenIdle(
      () => {
        this.initializeSequence();
      },
      { timeout: 600 },
    );
  }

  get currentIndex(): number {
    return this.internalCurrentIndex;
  }

  set currentIndex(value: number) {
    const roundValue = Math.round(value);

    if (roundValue !== this.internalCurrentIndex) {
      this.internalCurrentIndex = roundValue;

      this.renderFrame(this.currentIndex);
      this.data.set('current-frame', this.currentIndex.toString());
    }
  }

  private async initializeSequence() {
    if (this.hasCanvasTarget && this.canvasTarget) {
      this.ctx = this.canvasTarget.getContext('2d');

      await this.preloadImages();

      this.bind(window, 'resize', () => this.resize());
      this.resize();

      if (this.data.has('play-intro-on-init')) {
        this.playIntro();
      }
    }
  }

  private getLoadPath() {
    if (this.isMobile && this.hasMobilePathValue) {
      return this.mobilePathValue;
    }

    if (this.hasPathValue) {
      return this.pathValue;
    }
  }

  private async preloadImages() {
    const path = this.getLoadPath();

    if (
      this.hasFromValue &&
      typeof this.fromValue === 'number' &&
      this.hasToValue &&
      typeof this.toValue === 'number' &&
      path
    ) {
      const from = this.fromValue;
      const to = this.toValue;

      this.preloadPromise = Promise.allSettled(
        Array(to - from + 1)
          .fill(null)
          .map((_, index) => {
            const imageIndex = from + index;

            const fileName = [
              `${imageIndex}`.padStart(this.padLengthValue, '0'),
              this.extensionValue,
            ].join('.');

            const filePath = [path, fileName].join('/');

            return new Promise<void>((resolve, reject) => {
              const image = new Image();

              this.bind(
                image,
                'load',
                () => {
                  this.images.set(imageIndex, image);
                  resolve();
                },
                { once: true },
              );

              this.bind(
                image,
                'error',
                (error) => {
                  console.error(`Failed to load image ${image.src}`, error);
                  reject();
                },
                { once: true },
              );

              image.src = filePath;
            });
          }),
      );

      await this.preloadPromise;
      delete this.preloadPromise;
    }
  }

  private resize() {
    if (this.hasCanvasTarget && this.canvasTarget) {
      const { width, height } = this.element.getBoundingClientRect();

      this.canvasTarget.width = width;
      this.canvasTarget.height = height;

      this.renderFrame(this.currentIndex);
    }
  }

  private getImageRect(image: HTMLImageElement) {
    const { naturalWidth, naturalHeight } = image;

    if (this.hasCanvasTarget && this.canvasTarget) {
      const height = this.canvasTarget.height * (2 / 3);
      const width = height * (3 / 2);

      const x = Math.floor((width - naturalWidth) / 2);
      const y = Math.floor((height - naturalHeight) / 2);

      if (this.isMobile) {
        const offsetX = Math.floor(
          (this.canvasTarget.width - naturalWidth) / 2,
        );

        const offsetY = Math.floor(
          (this.canvasTarget.height - naturalHeight) / 2,
        );

        return {
          x: offsetX,
          y: offsetY,
          width: naturalWidth,
          height: naturalHeight,
        };
      }

      if (naturalHeight < height) {
        const factor = height / naturalHeight;
        const offsetX = Math.floor((width - naturalWidth * factor) / 2);

        return {
          x: offsetX,
          y: 0,
          width: naturalWidth * factor,
          height: naturalHeight * factor,
        };
      }

      return { x, y, width: naturalWidth, height: naturalHeight };
    }

    return { x: 0, y: 0, width: naturalWidth, height: naturalHeight };
  }

  private renderFrame(frameIndex: number) {
    if (this.ctx) {
      const image = this.images.get(frameIndex);

      if (image && image.complete && this.canvasTarget) {
        const rect = this.getImageRect(image);
        const { x, y, width, height } = rect;

        this.ctx.clearRect(
          0,
          0,
          this.canvasTarget.width,
          this.canvasTarget.height,
        );

        this.ctx.drawImage(image, x, y, width, height);

        this.dispatch(`frame:${frameIndex}`, {
          target: this.element,
          detail: { frameIndex },
        });
      }
    }
  }

  async playIntro() {
    if (
      this.currentAnimation !== 'intro' &&
      this.hasFromValue &&
      typeof this.fromValue === 'number' &&
      this.hasMidpointValue &&
      typeof this.midpointValue === 'number'
    ) {
      if (this.preloadPromise) {
        await this.preloadPromise;
      }

      const from = this.fromValue;
      const to = this.midpointValue;

      if (this.currentTween) {
        await this.currentTween.then();
      }

      this.currentAnimation = 'intro';

      this.currentTween = gsap.fromTo(
        this,
        { currentIndex: from },
        {
          currentIndex: to,
          duration: this.durationValue,
          ease: 'none',

          onComplete: () => {
            delete this.currentTween;
          },
        },
      );
    }
  }

  async playOutro() {
    if (
      this.currentAnimation === 'intro' &&
      this.hasMidpointValue &&
      typeof this.midpointValue === 'number' &&
      this.hasToValue &&
      typeof this.toValue === 'number'
    ) {
      if (this.preloadPromise) {
        await this.preloadPromise;
      }

      const from = this.midpointValue;
      const to = this.toValue;

      if (this.currentTween) {
        await this.currentTween.then();
      }

      this.currentAnimation = 'outro';

      this.currentTween = gsap.fromTo(
        this,
        { currentIndex: from },
        {
          currentIndex: to,
          duration: this.durationValue,
          ease: 'none',

          onComplete: () => {
            delete this.currentTween;
          },
        },
      );
    }
  }
}
