import { useIntersection } from 'stimulus-use';
import ApplicationController from '.';

export default class extends ApplicationController {
  static values = {
    id: String,
  };

  declare idValue?: string;
  declare readonly hasIdValue: boolean;

  private isMapInitialized: boolean = false;

  connect() {
    super.connect();
    useIntersection(this);
  }

  disconnect() {
    super.disconnect();

    if (this.isMapInitialized && window.iMaps) {
      window.iMaps.reset();
      this.isMapInitialized = false;
    }
  }

  appear() {
    if (!this.isMapInitialized && window.iMaps) {
      window.iMaps.init(false);
      this.isMapInitialized = true;
    }
  }

  private getMapInstance() {
    if (this.hasIdValue && this.idValue && window.iMaps) {
      const imap = window.iMaps.maps[this.idValue];

      if (imap && imap.map) {
        return imap.map;
      }
    }
  }

  private resetActions() {
    if (this.hasIdValue && this.idValue && window.iMapsActions) {
      window.iMapsActions.resetActions(this.idValue);
    }
  }

  goHome() {
    const map = this.getMapInstance();

    if (map) {
      map.goHome();
    }

    this.resetActions();
  }
}
