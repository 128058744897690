import VideoJS from 'video.js';
import ApplicationController from '.';

declare global {
  interface Window {
    HELP_IMPROVE_VIDEOJS?: boolean;
  }
}

window.HELP_IMPROVE_VIDEOJS = false;

export default class extends ApplicationController {
  static targets = ['video'];

  declare readonly videoTarget?: HTMLVideoElement;
  declare readonly hasVideoTarget: boolean;

  static values = {
    poster: { type: String, default: '' },
  };

  declare posterValue?: string;
  declare readonly hasPosterValue: boolean;

  connect() {
    if (this.hasVideoTarget && this.videoTarget) {
      VideoJS(this.videoTarget, this.options);
    }
  }

  get defaultOptions(): Parameters<typeof VideoJS>[1] {
    return {
      autoplay: false,
      preload: 'auto',
      controls: true,
      controlBar: {
        liveDisplay: true,
        pictureInPictureToggle: false,
      },
    };
  }

  get options(): Parameters<typeof VideoJS>[1] {
    const options = this.defaultOptions;

    if (this.hasPosterValue && this.posterValue) {
      options.poster = this.posterValue;
    }

    return options;
  }
}
