import { CountUp } from 'countup.js';

import ApplicationController from '.';

export default class extends ApplicationController {
  static targets = ['display'];

  declare readonly displayTarget?: HTMLElement;
  declare readonly hasDisplayTarget: boolean;

  static values = {
    target: Number,
    delay: { type: Number, default: 0 },
    decimalPlaces: { type: Number, default: 0 },
  };

  declare targetValue: number;

  declare delayValue?: number;
  declare readonly hasDelayValue: boolean;

  declare decimalPlacesValue?: number;
  declare readonly hasDecimalPlacesValue: boolean;

  private declare instance?: CountUp;

  connect() {
    super.connect();

    if (this.hasDisplayTarget && this.displayTarget) {
      this.instance = new CountUp(this.displayTarget, this.targetValue, {
        decimalPlaces: this.hasDecimalPlacesValue
          ? this.decimalPlacesValue
          : undefined,

        enableScrollSpy: true,
        scrollSpyDelay: this.hasDelayValue ? this.delayValue : 0,
      });

      this.instance.start();
    }
  }
}
