import 'requestidlecallback-polyfill';

import {
  type Application,
  AttributeObserver,
  type ControllerConstructor,
} from '@hotwired/stimulus';

type ResolverFunction = (
  controllerName: string,
) => Promise<ControllerConstructor | undefined>;

type ControllerLoader = () => Promise<{ default: ControllerConstructor }>;

type ImportedModules = ReturnType<ImportMeta['glob']>;
type KeyIdentifiers = Record<string, ControllerLoader>;

export default class StimulusControllerResolver {
  private application: Application;
  private loadingControllers: Record<string, boolean> = {};
  private resolverFn: ResolverFunction;
  private observer: AttributeObserver;

  constructor(application: Application, resolverFn: ResolverFunction) {
    this.application = application;
    this.resolverFn = resolverFn;

    this.loadStimulusControllers = this.loadStimulusControllers.bind(this);

    this.observer = new AttributeObserver(
      application.element,
      application.schema.controllerAttribute,
      {
        elementMatchedAttribute: this.loadStimulusControllers,
        elementAttributeValueChanged: this.loadStimulusControllers,
      },
    );
  }

  start() {
    this.observer.start();
  }

  stop() {
    this.observer.stop();
  }

  static install(application: Application, resolverFn: ResolverFunction) {
    const instance = new StimulusControllerResolver(application, resolverFn);

    instance.start();

    return instance;
  }

  loadStimulusControllers(element: HTMLElement) {
    const controllerAttribute = element.getAttribute(
      this.application.schema.controllerAttribute,
    );

    if (controllerAttribute) {
      const controllerNames = controllerAttribute
        .trim()
        .split(/\s+/)
        .map((name) => name.trim())
        .filter((name) => Boolean(name));

      controllerNames.forEach((controllerName) =>
        requestIdleCallback(() => this.loadController(controllerName), {
          timeout: 500,
        }),
      );
    }
  }

  async loadController(controllerName: string) {
    if (
      !this.loadingControllers[controllerName] &&
      !this.application.router['modulesByIdentifier'].has(controllerName)
    ) {
      this.loadingControllers[controllerName] = true;

      const controllerDefinition = await this.resolverFn(controllerName);

      if (controllerDefinition) {
        this.application.register(controllerName, controllerDefinition);
      }

      delete this.loadingControllers[controllerName];
    }
  }
}

export function createViteGlobResolver(...ImportedModules: ImportedModules[]) {
  const controllerLoaders = mapGlobKeysToIdentifiers(ImportedModules);

  console.debug(
    `%c⍑ Queueing async controllers: ${Object.keys(controllerLoaders).join(
      ', ',
    )}`,
    'color: #aaaa00',
  );

  return async (controllerName: string) => {
    const loader = controllerLoaders[controllerName];

    if (!loader) {
      console.debug(
        `%c⏛ Cannot resolve async controller: ${controllerName}`,
        'color: #ff7700',
      );

      return undefined;
    }

    console.debug(
      `%c🗲 Loading async controller: ${controllerName}`,
      'color: #ffff00',
    );

    return (await loader()).default;
  };
}

// Vite's glob keys include the complete path of each file, but we need the
// Stimulus identifiers. This function merges an array of glob results into one
// object, where the key is the Stimulus identifier.
// Example:
//   mapGlobKeysToIdentifiers(
//     { './a_controller.js': fn1 },
//     { './b_controller.js': fn2 }
//   )
//   => { a: fn1, b: fn2 }
export function mapGlobKeysToIdentifiers(ImportedModules: ImportedModules[]) {
  return Object.entries<ControllerLoader>(
    Object.assign({}, ...ImportedModules),
  ).reduce((acc, [key, controllerFn]) => {
    const identifier = identifierForGlobKey(key);

    if (identifier) {
      acc[identifier] = controllerFn;
    }

    return acc;
  }, {} as KeyIdentifiers);
}

export const CONTROLLER_FILENAME_REGEX =
  /^(?:.*?(?:controllers|components)\/|\.?\.\/)?(.+)(?:[_-]controller\..+?)$/;

export function identifierForGlobKey(key: string) {
  const logicalName = (key.match(CONTROLLER_FILENAME_REGEX) || [])[1];

  if (logicalName) {
    return logicalName.replace(/_/g, '-').replace(/\//g, '--');
  }
}
