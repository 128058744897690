import SimpleBar from 'simplebar';
import ApplicationController from '.';

export default class extends ApplicationController<HTMLElement> {
  private instance?: SimpleBar;

  connect() {
    super.connect();

    if (this.element) {
      this.instance = new SimpleBar(this.element);
      this.bindScrollListener();
    }
  }

  disconnect() {
    super.disconnect();

    if (this.instance) {
      this.instance.unMount();
    }
  }

  getScrollElement() {
    if (this.instance) {
      return this.instance.getScrollElement();
    }

    return null;
  }

  bindScrollListener() {
    const scrollElement = this.getScrollElement();

    if (scrollElement) {
      this.bind(scrollElement, 'scroll', (event) => {
        const { innerHeight } = window;
        const { scrollTop, scrollHeight } = scrollElement;

        const percent = scrollTop / (scrollHeight - innerHeight);
        const percentWindow = scrollTop / innerHeight;

        this.dispatch('scroll', {
          target: window,
          detail: {
            event,
            innerHeight,
            scrollTop,
            scrollHeight,
            percent,
            percentWindow,
          },
        });
      });
    }
  }
}
