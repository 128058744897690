import ApplicationController from '.';
import type ToggleController from './toggle-controller';

type ToggleEvent = CustomEvent<{
  group: string;
  instance: ToggleController;
}>;

export default class extends ApplicationController {
  static targets = ['chip', 'tabContent'];

  declare readonly chipTarget?: HTMLElement;
  declare readonly hasChipTarget: boolean;

  declare readonly tabContentTargets: HTMLElement[];

  static values = {
    group: String,
  };

  declare groupValue?: string;
  declare readonly hasGroupValue: boolean;

  updateIfSameGroup(event: ToggleEvent) {
    if (
      this.hasGroupValue &&
      this.groupValue &&
      this.groupValue === event.detail.group &&
      this.hasChipTarget &&
      this.chipTarget
    ) {
      if (event.detail.instance) {
        const { element } = event.detail.instance;

        if (element) {
          const { offsetLeft: left, offsetWidth: width } = element;

          this.chipTarget.style.setProperty('left', `${left}px`);
          this.chipTarget.style.setProperty('width', `${width}px`);

          if (element.hasAttribute('aria-controls')) {
            const targetId = element.getAttribute('aria-controls');

            if (targetId) {
              this.showTab(targetId);
            }
          }
        }
      }
    }
  }

  private showTab(id: string) {
    this.tabContentTargets.forEach((tab) => {
      tab.setAttribute('aria-current', (tab.id !== id).toString());
    });
  }
}
