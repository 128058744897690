import { Controller } from '@hotwired/stimulus';

type EventListenerData = {
  receiver: EventTarget;
  event: string;
  callback: EventListenerOrEventListenerObject;
};

type ScrollCallback = (this: Window, event: Event) => unknown;
type ScrollCallbackOptions = boolean | AddEventListenerOptions | undefined;
type ScrollCallbackData = Array<[ScrollCallback, ScrollCallbackOptions]>;

type Feature =
  | 'hover'
  | 'any-hover'
  | 'pointer:none'
  | 'pointer:coarse'
  | 'pointer:fine'
  | 'theme:light'
  | 'theme:dark'
  | 'motion:default'
  | 'motion:reduce';

class ApplicationController<
  ElementType extends Element = Element,
> extends Controller<ElementType> {
  protected declare static debugDispatch: boolean;
  protected declare static selfAssignInstance: boolean;

  private __eventsListeners: EventListenerData[] = [];
  private __timeouts: (number | NodeJS.Timeout)[] = [];
  private __intervals: (number | NodeJS.Timeout)[] = [];
  private __idleCallbacks: number[] = [];
  private __scrollCallbacks: ScrollCallbackData = [];

  connect() {
    super.connect();

    if (
      (this.constructor as typeof ApplicationController).selfAssignInstance ||
      (this.element instanceof HTMLElement &&
        'selfAssignInstance' in this.element.dataset)
    ) {
      (this.element as any)[`${this.identifier}Controller`] = this;
    }
  }

  disconnect() {
    this.clearAllTimeouts();
    this.clearAllIntervals();
    this.clearAllIdleCallbacks();
  }

  bind(
    receiver: EventTarget,
    events: string,
    callback: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions | undefined,
  ) {
    const listeners = events.split(' ').map((event) => {
      const data = { receiver, event, callback };

      receiver.addEventListener(event, callback, options);
      this.__eventsListeners.push(data);

      return data;
    });

    if (listeners.length === 1) {
      return listeners[0];
    }

    return listeners;
  }

  unbind(...events: string[]) {
    const matchingEvents = this.__eventsListeners.filter(({ event }) =>
      events.includes(event),
    );

    const rest = this.__eventsListeners.filter(
      ({ event }) => !events.includes(event),
    );

    matchingEvents.forEach(({ receiver, event, callback }) => {
      receiver.removeEventListener(event, callback);
    });

    this.__eventsListeners = rest;
  }

  /**
   * Unbinds all given listeners.
   *
   * @param {Object[]} listeners
   */
  unbindListeners(...listeners: EventListenerData[]) {
    listeners.forEach((listener) => {
      const { receiver, event, callback } = listener;
      const index = this.__eventsListeners.indexOf(listener);

      receiver.removeEventListener(event, callback);
      this.__eventsListeners.splice(index, 1);
    });
  }

  /**
   * Executes a callback function after some delay.\
   * Same as window.setTimeout, but timeouts are cancelled automatically on disconnect.
   * - Don't forget to call `super.disconnect()` if you override the disconnect hook.
   *
   * @param {function} callback
   * @param {number} [delay=0]
   */
  later(callback: () => void, delay: number = 0) {
    const id = setTimeout(callback, delay);

    this.__timeouts.push(id);

    return id;
  }

  /**
   * Clears all registered timeouts without waiting for controller disconnect.
   */
  clearAllTimeouts() {
    this.__timeouts.forEach((id) => clearTimeout(id));
    this.__timeouts = [];
  }

  /**
   * Executes a callback function repeatedly every interval milliseconds.\
   * Same as window.setInterval, but intervals are cancelled automatically on disconnect.
   * - Don't forget to call `super.disconnect()` if you override the disconnect hook.
   *
   * @param {function} callback
   * @param {number} [interval=0]
   */
  every(callback: () => void, interval: number = 0) {
    const id = setInterval(callback, interval);

    this.__intervals.push(id);

    return id;
  }

  /**
   * Clears all registered intervals without waiting for controller disconnect.
   */
  clearAllIntervals() {
    this.__intervals.forEach((id) => clearInterval(id));
    this.__intervals = [];
  }

  whenIdle(callback: IdleRequestCallback, options?: IdleRequestOptions) {
    this.__idleCallbacks.push(requestIdleCallback(callback, options));
  }

  clearAllIdleCallbacks() {
    this.__idleCallbacks.forEach((id) => cancelIdleCallback(id));
    this.__idleCallbacks = [];
  }

  onScroll(callback: ScrollCallback, options?: ScrollCallbackOptions) {
    window.addEventListener('scroll', callback, options);
    this.__scrollCallbacks.push([callback, options]);
  }

  clearAllScrollCallbacks() {
    this.__scrollCallbacks.forEach(([callback, options]) => {
      window.removeEventListener('scroll', callback, options);
    });

    this.__scrollCallbacks = [];
  }

  hasFeature(feature: Feature): boolean {
    switch (feature) {
      case 'hover': {
        return window.matchMedia('(hover: hover)').matches;
      }

      case 'any-hover': {
        return window.matchMedia('(any-hover: hover)').matches;
      }

      case 'pointer:none': {
        return window.matchMedia('(pointer: none)').matches;
      }

      case 'pointer:coarse': {
        return window.matchMedia('(pointer: coarse)').matches;
      }

      case 'pointer:fine': {
        return window.matchMedia('(pointer: fine)').matches;
      }

      case 'theme:light': {
        return window.matchMedia('(prefers-color-scheme: light)').matches;
      }

      case 'theme:dark': {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
      }

      case 'motion:default': {
        return window.matchMedia('(prefers-reduced-motion: no-preference)')
          .matches;
      }

      case 'motion:reduce': {
        return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
      }
    }
  }

  get isMobile() {
    return window.innerWidth < 1024;
  }

  dispatch(
    eventName: string,
    options?:
      | Partial<{
          target: Element | Window | Document;
          detail: Object;
          prefix: string;
          bubbles: boolean;
          cancelable: boolean;
        }>
      | undefined,
  ): CustomEvent<Object> {
    if (
      (this.constructor as typeof ApplicationController).debugDispatch ||
      (this.element instanceof HTMLElement &&
        'debugDispatch' in this.element.dataset)
    ) {
      console.debug(
        '%cDISPATCH',
        'padding:2px 5px;border-radius:4px;background:#312e81;border:1px solid #4338ca;color:#e0e7ff',
        `${this.identifier}:${eventName}`,
        options,
      );
    }

    return super.dispatch(eventName, options);
  }
}

export default ApplicationController;
