import { useClickOutside } from 'stimulus-use';
import ApplicationController from '.';

interface SelectOption {
  value: string | number;
  label: string;
  permalink?: string;
  selected?: boolean;
  disabled?: boolean;
}

export default class extends ApplicationController<HTMLElement> {
  static values = {
    options: Array,
  };

  declare optionsValue: SelectOption[];
  declare readonly hasOptionsValue: boolean;

  static targets = [
    'toggleButton',
    'select',
    'option',
    'template',
    'listbox',
    'displayLabel',
  ];

  declare readonly toggleButtonTarget?: HTMLButtonElement;
  declare readonly hasToggleButtonTarget: boolean;

  declare readonly selectTarget?: HTMLSelectElement;
  declare readonly hasSelectTarget: boolean;

  declare readonly optionTargets: HTMLOptionElement[];
  declare readonly hasOptionTarget: boolean;

  declare readonly templateTarget?: HTMLTemplateElement;
  declare readonly hasTemplateTarget: boolean;

  declare readonly listboxTarget?: HTMLElement;
  declare readonly hasListboxTarget: boolean;

  declare readonly displayLabelTarget?: HTMLElement;
  declare readonly hasDisplayLabelTarget: boolean;

  private currentListbox?: HTMLElement;

  connect(): void {
    super.connect();

    useClickOutside(this);
    this.updateDisplay();
  }

  get isOpen(): boolean {
    return Boolean(
      this.hasToggleButtonTarget &&
        this.toggleButtonTarget &&
        this.toggleButtonTarget.hasAttribute('aria-expanded') &&
        this.toggleButtonTarget.getAttribute('aria-expanded') === 'true',
    );
  }

  get selectedValue(): string | undefined {
    if (this.hasSelectTarget && this.selectTarget) {
      return this.selectTarget.value;
    }
  }

  set selectedValue(value: string) {
    if (value && this.hasSelectTarget && this.selectTarget) {
      this.selectTarget.value = value;

      this.updateDisplayLabel(value);
      this.dispatch('change', { detail: { value } });
    }
  }

  private updateDisplayLabel(value: string) {
    if (this.hasDisplayLabelTarget && this.displayLabelTarget) {
      const label = this.getLabel(value);

      if (label) {
        this.displayLabelTarget.innerText = label;
      }
    }
  }

  private cloneListbox() {
    if (this.hasTemplateTarget && this.templateTarget) {
      const clone = this.templateTarget.content.cloneNode(
        true,
      ) as DocumentFragment;

      const node = clone.children[0] as HTMLElement;

      if (node) {
        this.updateListbox(node);
        this.element.appendChild(node);

        this.currentListbox = node;
      }
    }
  }

  private updateListbox(node: HTMLElement) {
    const options = Array.from(
      node.querySelectorAll('button[role="option"]'),
    ) as HTMLButtonElement[];

    options.forEach((option) => {
      const { value } = option;

      if (value === this.selectedValue) {
        option.setAttribute('aria-selected', 'true');
      } else {
        option.setAttribute('aria-selected', 'false');
      }
    });
  }

  private getLabel(value: string) {
    if (this.hasSelectTarget && this.selectTarget) {
      const option = Array.from(this.selectTarget.options).find(
        (option) => option.value === value,
      );

      if (option) {
        return option.text;
      }
    }
  }

  open() {
    if (this.isMobile) {
      return false;
    }

    this.close();
    this.cloneListbox();

    if (this.hasToggleButtonTarget && this.toggleButtonTarget) {
      this.toggleButtonTarget.setAttribute('aria-expanded', 'true');
    }
  }

  close() {
    if (this.hasListboxTarget && this.listboxTarget) {
      this.listboxTarget.remove();
      delete this.currentListbox;
    }

    if (this.hasToggleButtonTarget && this.toggleButtonTarget) {
      this.toggleButtonTarget.setAttribute('aria-expanded', 'false');
    }
  }

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  selectOption(event: Event) {
    const { currentTarget } = event;

    if (currentTarget instanceof HTMLButtonElement) {
      const { value } = currentTarget;

      if (value) {
        this.selectedValue = value;

        if (this.currentListbox) {
          this.updateListbox(this.currentListbox);
        }
      }
    }

    this.close();
  }

  updateDisplay() {
    if (this.hasSelectTarget && this.selectTarget) {
      const value = this.selectTarget.value;

      if (value) {
        this.updateDisplayLabel(value);
      }
    }
  }

  private getPermalink(value: string) {
    if (this.hasOptionsValue && this.optionsValue) {
      const option = this.optionsValue.find(
        (option) => option.value.toString() === value,
      );

      return option?.permalink;
    }

    return null;
  }

  navigate(event: CustomEvent<{ value: string }>) {
    if (event.detail && event.detail.value) {
      const permalink = this.getPermalink(event.detail.value);

      if (permalink) {
        this.dispatch('navigation:requested', {
          prefix: 'swup',
          detail: { url: permalink },
        });
      }
    }
  }
}
