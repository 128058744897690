import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import ApplicationController from '.';

gsap.registerPlugin(ScrollTrigger);

export default class extends ApplicationController<HTMLElement> {
  static values = {
    offset: Number,
    enableOnMobile: Boolean,
  };

  declare offsetValue?: number;
  declare readonly hasOffsetValue: boolean;

  declare enableOnMobileValue: boolean;

  connect(): void {
    super.connect();

    if (this.hasOffsetValue && this.offsetValue && this.isEnabled) {
      this.element.style.setProperty('margin-top', '0');

      gsap.to(this.element, {
        scrollTrigger: {
          trigger: this.element,
          scrub: true,
        },

        y: this.offsetValue,
        ease: 'none',
      });
    }
  }

  get isEnabled() {
    if (this.isMobile && !this.enableOnMobileValue) {
      return false;
    }

    return !this.hasFeature('motion:reduce');
  }
}
