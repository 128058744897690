import { useMemo } from 'stimulus-use';
import ApplicationController from '.';

export default class extends ApplicationController {
  static values = {
    timezone: String,
  };

  declare timezoneValue?: string;
  declare readonly hasTimezoneValue: boolean;

  static memos = ['formatter'];

  connect() {
    super.connect();
    useMemo(this);

    this.every(() => this.updateTime(), 1_000);
    this.updateTime();
  }

  get formatter() {
    return new Intl.DateTimeFormat(undefined, {
      timeZone: this.timezoneValue,
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
  }

  get currentFormattedTime() {
    return this.formatter.format(new Date());
  }

  private updateTime() {
    this.element.textContent = this.currentFormattedTime;
  }
}
