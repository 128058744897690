import ApplicationController from '.';
import { randomRange } from '../lib/math';

export default class extends ApplicationController {
  static targets = ['template', 'track'];

  declare readonly templateTarget?: HTMLTemplateElement;
  declare readonly hasTemplateTarget: boolean;

  declare readonly trackTarget?: HTMLElement;
  declare readonly hasTrackTarget: boolean;

  static values = {
    delayInterval: Array,
    durationInterval: Array,
  };

  declare delayIntervalValue: [number, number];
  declare readonly hasDelayIntervalValue: boolean;

  declare durationIntervalValue: [number, number];
  declare readonly hasDurationIntervalValue: boolean;

  connect(): void {
    super.connect();

    if (this.canSpawnLines && !this.hasFeature('motion:reduce')) {
      this.scheduleSpawn(
        Math.round(
          randomRange(
            this.delayIntervalValue[0] * 0.1,
            this.delayIntervalValue[1] * 0.1,
          ),
        ),
      );
    }
  }

  get canSpawnLines(): boolean {
    return Boolean(
      this.hasTemplateTarget &&
        this.templateTarget &&
        this.hasTrackTarget &&
        this.trackTarget,
    );
  }

  scheduleSpawn(delay: number) {
    this.later(() => {
      const duration = Math.round(randomRange(...this.durationIntervalValue));

      this.spawnLine(duration);
      this.scheduleSpawn(Math.round(randomRange(...this.delayIntervalValue)));
    }, delay);
  }

  spawnLine(duration: number) {
    if (
      this.hasTemplateTarget &&
      this.templateTarget &&
      this.hasTrackTarget &&
      this.trackTarget
    ) {
      const clone = this.templateTarget.content.cloneNode(
        true,
      ) as DocumentFragment;

      const node = clone.children[0] as HTMLElement;

      if (node) {
        node.style.setProperty('animation-duration', `${duration}ms`);
        this.trackTarget.appendChild(clone);
      }
    }
  }
}
