import { useMemo } from 'stimulus-use';
import ApplicationController from '.';

type MvpfSubmitEvent = CustomEvent<{
  success: boolean;
}>;

export default class extends ApplicationController {
  static memos = ['formElement'];

  connect() {
    super.connect();
    useMemo(this);

    if (this.formElement) {
      this.bind(
        this.formElement,
        'mvpf:ajax-submitted',
        this.handleOnSubmit as EventListenerOrEventListenerObject,
      );
    }
  }

  get formElement(): HTMLFormElement | null {
    return this.element.querySelector('form.mvpf__form');
  }

  handleOnSubmit = (event: MvpfSubmitEvent) => {
    const { detail } = event;
    const { success } = detail;

    if (
      document.activeElement &&
      document.activeElement instanceof HTMLElement &&
      'blur' in document.activeElement
    ) {
      document.activeElement.blur();
    }

    if (success && this.formElement) {
      this.formElement.reset();
    }
  };
}
