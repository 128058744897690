import type SwiperModules from 'swiper/modules';
import { Swiper, SwiperOptions } from 'swiper/types';

import ApplicationController from '.';

type SwiperModuleName = keyof typeof SwiperModules;

export default class extends ApplicationController<HTMLElement> {
  static targets = ['root', 'pagination'];

  declare readonly rootTarget?: HTMLElement;
  declare readonly rootTargets: HTMLElement[];
  declare readonly hasRootTarget: boolean;

  declare readonly paginationTarget?: HTMLElement;
  declare readonly hasPaginationTarget: boolean;

  static values = {
    options: { type: Object, default: {} },
    modules: { type: Array, default: [] },
  };

  declare optionsValue?: SwiperOptions;
  declare readonly hasOptionsValue: boolean;

  declare modulesValue?: SwiperModuleName[];
  declare readonly hasModulesValue: boolean;

  declare instance?: Swiper;

  connect() {
    super.connect();
    this.initializeSwiper();
  }

  disconnect() {
    super.disconnect();

    if (this.instance) {
      this.instance.destroy();
      delete this.instance;
    }
  }

  get rootSwiperElement() {
    if (this.hasRootTarget && this.rootTarget) {
      return this.rootTarget;
    }

    return this.element;
  }

  get defaultOptions() {
    return {} as SwiperOptions;
  }

  get swiperOptions() {
    if (this.hasOptionsValue && this.optionsValue) {
      return { ...this.defaultOptions, ...this.optionsValue };
    }

    return this.defaultOptions;
  }

  async importOptions() {
    const baseOptions = this.swiperOptions;

    if (this.hasPaginationTarget && this.paginationTarget) {
      if (typeof baseOptions.pagination === 'object') {
        baseOptions.pagination.el = this.paginationTarget;
      }
    }

    if (this.hasModulesValue && this.modulesValue) {
      const allModules = await import('swiper/modules');

      const modules = this.modulesValue
        .map((moduleName) => allModules[moduleName])
        .filter(Boolean);

      return { modules, ...baseOptions };
    }

    return baseOptions;
  }

  async initializeSwiper() {
    const { Swiper } = await import('swiper');
    const options = await this.importOptions();

    this.instance = new Swiper(this.rootSwiperElement, options);
  }
}
