import ApplicationController from '.';

export default class extends ApplicationController {
  static targets = ['reference', 'panel'];

  declare readonly referenceTarget?: HTMLElement;
  declare readonly referenceTargets: HTMLElement[];
  declare readonly hasReferenceTarget: boolean;

  declare readonly panelTarget?: HTMLElement;
  declare readonly panelTargets: HTMLElement[];
  declare readonly hasPanelTarget: boolean;

  connect(): void {
    super.connect();

    this.bind(window, 'resize', this.updateOffset);
    this.updateOffset();
  }

  get bodyMargin() {
    if (this.isMobile) {
      return 16;
    }

    return 40;
  }

  getReferenceOffset() {
    if (this.hasReferenceTarget && this.referenceTarget) {
      const { left } = this.referenceTarget.getBoundingClientRect();

      return Math.abs(Math.min(left - this.bodyMargin, 0));
    }

    return 0;
  }

  updateOffset = () => {
    if (this.hasPanelTarget && this.panelTarget) {
      const offset = this.getReferenceOffset();

      this.panelTarget.style.setProperty(
        'transform',
        `translate3d(${offset}px, 0, 0)`,
      );
    }
  };
}
