import { useMemo } from 'stimulus-use';
import ApplicationController from '.';

export type RawThresholds = Record<number, string>;

export type ParsedThresholdEntry = [number, string];

export type MappedThreshold = {
  threshold: number;
  action: string;
};

export type ThresholdStateType = '__NONE__' | 'single' | 'multi';

export type ThresholdStateValue = {
  __NONE__: null;
  single: 'on' | 'off';
  multi: string;
};

export interface ThresholdStateItem<T extends ThresholdStateType> {
  type: T;
  value: ThresholdStateValue[T];
}

export type ThresholdState =
  | ThresholdStateItem<'__NONE__'>
  | ThresholdStateItem<'single'>
  | ThresholdStateItem<'multi'>;

export default class extends ApplicationController {
  static values = {
    threshold: Number,
    thresholds: Object,
    staticMobile: String,
  };

  declare thresholdValue?: number;
  declare readonly hasThresholdValue: boolean;

  declare thresholdsValue?: RawThresholds;
  declare readonly hasThresholdsValue: boolean;

  declare staticMobileValue?: ThresholdStateValue['single'];
  declare readonly hasStaticMobileValue: boolean;

  static memos = ['mappedThresholds'];

  private currentState: ThresholdState = {
    type: '__NONE__',
    value: null,
  };

  connect() {
    super.connect();
    useMemo(this);

    if (this.isMobile && this.hasStaticMobileValue && this.staticMobileValue) {
      this.setCurrentState('single', this.staticMobileValue);
    } else {
      this.onScroll(this.onScrollCallback, {
        passive: true,
      });

      this.onScrollCallback();
    }
  }

  get isSingle() {
    return this.hasThresholdValue && this.thresholdValue;
  }

  get isMulti() {
    return !this.isSingle && this.hasThresholdsValue && this.thresholdsValue;
  }

  get mappedThresholds(): MappedThreshold[] {
    if (this.isMulti && this.thresholdsValue) {
      return Object.entries(this.thresholdsValue)
        .map(
          ([threshold, action]) =>
            [Number.parseInt(threshold), action] as ParsedThresholdEntry,
        )
        .sort(([a], [b]) => a - b)
        .map(([threshold, action]) => ({ threshold, action }));
    }

    return [];
  }

  private dispatchPreviousState() {
    if (this.currentState && this.isMulti) {
      const { type, value } = this.currentState;

      if (type === '__NONE__') {
        this.dispatch('none:off');
      } else {
        this.dispatch([value, 'off'].join(':'));
      }
    }
  }

  private isCurrentState<T extends ThresholdStateType>(
    type: T,
    value: ThresholdStateValue[T],
  ) {
    return (
      this.currentState &&
      this.currentState.type === type &&
      this.currentState.value === value
    );
  }

  private setCurrentState<T extends ThresholdStateType>(
    type: T,
    value: ThresholdStateValue[T],
  ) {
    if (!this.isCurrentState(type, value)) {
      this.dispatchPreviousState();

      this.currentState.type = type;
      this.currentState.value = value;

      if (this.isSingle && value) {
        this.dispatch(value);
      } else {
        this.dispatch([type === '__NONE__' ? 'none' : value, 'on'].join(':'));
      }
    }
  }

  private updateSingleThreshold(position: number) {
    if (this.hasThresholdValue && this.thresholdValue) {
      if (position >= this.thresholdValue) {
        this.setCurrentState('single', 'on');
      } else {
        this.setCurrentState('single', 'off');
      }
    }
  }

  private findActiveMultiThreshold(position: number) {
    return this.mappedThresholds
      .filter(({ threshold }) => position >= threshold)
      .reduce(
        (acc, item) => {
          if (acc) {
            return item.threshold >= acc.threshold ? item : acc;
          }

          return item;
        },
        null as MappedThreshold | null,
      );
  }

  private updateMultiThresholds(position: number) {
    const threshold = this.findActiveMultiThreshold(position);

    if (threshold) {
      this.setCurrentState('multi', threshold.action);
    } else {
      this.setCurrentState('__NONE__', null);
    }
  }

  onScrollCallback = () => {
    const { scrollY } = window;

    if (this.isSingle) {
      this.updateSingleThreshold(scrollY);
    } else {
      this.updateMultiThresholds(scrollY);
    }
  };
}
